import React, { useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const partners = [
  { name: 'Partner 1', logo: '/assets/partners/partner1.png' },
  { name: 'Partner 2', logo: '/assets/partners/partner8.png' },
  { name: 'Partner 3', logo: '/assets/partners/partner3.png' },
  { name: 'Partner 4', logo: '/assets/partners/partner4.png' },
  { name: 'Partner 5', logo: '/assets/partners/partner5.png' },
  { name: 'Partner 6', logo: '/assets/partners/partner6.png' },
  { name: 'Partner 7', logo: '/assets/partners/partner7.png' },
];

const PartnersSlider = () => {
  const scrollLeft = () => {
    document.getElementById('partners-container').scrollLeft -= 200;
  };

  const scrollRight = () => {
    document.getElementById('partners-container').scrollLeft += 200;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      scrollLeft();
    } else if (event.key === 'ArrowRight') {
      scrollRight();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-4 text-center">Our Partners</h2>
        <div className="relative">
          <div className="flex items-center justify-center">
            <button onClick={scrollLeft} className="p-2 rounded-full bg-white shadow-md hover:bg-gray-100 absolute left-0 z-10" aria-label="Scroll left">
              <ChevronLeft className="h-6 w-6" />
            </button>
            <div id="partners-container" className="flex overflow-x-auto scrollbar-hide space-x-4 py-4 px-2 max-w-full">
              {partners.map((partner, index) => (
                <div key={index} className="flex-shrink-0">
                  <img
                    src={partner.logo}
                    alt={partner.name}
                    className="h-20 w-auto object-contain"
                  />
                </div>
              ))}
            </div>
            <button onClick={scrollRight} className="p-2 rounded-full bg-white shadow-md hover:bg-gray-100 absolute right-0 z-10" aria-label="Scroll right">
              <ChevronRight className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersSlider;