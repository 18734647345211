import React, { useState, useEffect } from 'react'
import { Search, User, HelpCircle, Menu } from 'lucide-react'
import { Link } from 'react-router-dom'

export default function Header({ toggleCategoryMenu }) {
  const [isScrolled, setIsScrolled] = useState(false)
  const [categories, setCategories] = useState([])
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [isSearching, setIsSearching] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      setIsScrolled(scrollPosition > 0)
    }

    window.addEventListener('scroll', handleScroll)
    
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/maincategories`)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const data = await response.json()
        setCategories(data)
      } catch (error) {
        console.error('Error fetching categories:', error)
      }
    }

    fetchCategories()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleToggleCategoryMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsCategoryMenuOpen((prev) => !prev)
  }

  const handleCategoryClick = (category) => {
    console.log('Category selected:', category)
    setIsCategoryMenuOpen(false)
  }

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    if (!searchQuery.trim()) return

    setIsSearching(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/products/search?q=${encodeURIComponent(searchQuery)}`)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const data = await response.json()
      setSearchResults(data)
      console.log('Search results:', data)
    } catch (error) {
      console.error('Error searching products:', error)
    } finally {
      setIsSearching(false)
    }
  }

  return (
    <>
      <header className="fixed top-0 left-0 right-0 z-50">
        <div className={`transition-all duration-300 ${isScrolled ? 'bg-white bg-opacity-70 backdrop-blur-md shadow-md' : 'bg-white'}`}>
          <div className="container mx-auto px-4 py-2 md:py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <button onClick={handleToggleCategoryMenu} className="mr-2 md:hidden">
                  <Menu className="h-6 w-6" />
                  <span className="sr-only">Toggle Categories</span>
                </button>
                <Link to="/">
                  <img
                    src={process.env.REACT_APP_LOGO_URL}
                    alt="Electric Link Logo"
                    className="h-16 w-auto md:h-20"
                  />
                </Link>
              </div>
              <div className="hidden md:flex items-center justify-center flex-1 ml-8">
                <div className="w-full max-w-2xl">
                  <form onSubmit={handleSearch} className="flex">
                    <input
                      type="search"
                      placeholder="Search products, brands and categories"
                      className="w-full rounded-l-md border border-gray-300 p-3 focus:outline-none focus:ring-2 focus:ring-[#d01f16] focus:border-transparent"
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                    <button 
                      type="submit"
                      className="bg-[#d01f16] text-white px-6 py-3 rounded-r-md hover:bg-[#b01a12] transition-colors duration-200"
                      disabled={isSearching}
                    >
                      {isSearching ? (
                        <span className="animate-spin">⌛</span>
                      ) : (
                        <Search className="h-6 w-6" />
                      )}
                      <span className="sr-only">Search</span>
                    </button>
                  </form>
                </div>
              </div>
              <div className="flex items-center space-x-6">
                <button className="flex flex-col items-center">
                  <User className="h-6 w-6 md:h-7 md:w-7" />
                  <span className="text-xs mt-1 hidden md:inline">Account</span>
                </button>
                <Link to="/contacts" className="flex flex-col items-center">
                  <HelpCircle className="h-6 w-6 md:h-7 md:w-7" />
                  <span className="text-xs mt-1 hidden md:inline">Help</span>
                </Link>
              </div>
            </div>

            {isCategoryMenuOpen && (
              <div className="absolute left-0 right-0 mt-2 bg-white shadow-lg rounded-md z-50 md:hidden">
                <div className="py-2">
                  {categories.map((category, index) => (
                    <button
                      key={index}
                      onClick={() => handleCategoryClick(category)}
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      {category}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div className="mt-2 md:hidden">
              <form onSubmit={handleSearch} className="flex">
                <input
                  type="search"
                  placeholder="Search products, brands and categories"
                  className="w-full rounded-l-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-[#d01f16] focus:border-transparent"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                <button 
                  type="submit"
                  className="bg-[#d01f16] text-white px-4 py-2 rounded-r-md hover:bg-[#b01a12] transition-colors duration-200"
                  disabled={isSearching}
                >
                  {isSearching ? (
                    <span className="animate-spin">⌛</span>
                  ) : (
                    <Search className="h-5 w-5" />
                  )}
                  <span className="sr-only">Search</span>
                </button>
              </form>
            </div>
          </div>
        </div>
        
        <div className="bg-gradient-to-r from-gray-900 to-gray-700 text-white py-2 hidden md:block">
          <div className="container mx-auto px-4">
            <nav className="flex justify-center space-x-6">
              <Link to="/" className="hover:text-gray-300 transition-colors duration-200">Home</Link>
              <Link to="/about" className="hover:text-gray-300 transition-colors duration-200">About Us</Link>
              <Link to="/service-center" className="hover:text-gray-300 transition-colors duration-200">Service Center</Link>
              <Link to="/careers" className="hover:text-gray-300 transition-colors duration-200">Careers</Link>
              <Link to="/contacts" className="hover:text-gray-300 transition-colors duration-200">Contact Us</Link>
            </nav>
          </div>
        </div>
      </header>
      <div className="h-[35px] md:h-[35px]"></div> {/* Spacer to prevent content overlay */}
    </>
  )
}