import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronLeft, Loader, ChevronRight, ChevronDown, Phone, Mail, MessageCircle } from 'lucide-react';
import Product from './Product';
import Header from './Header';
import Footer from './Footer';

const ChildCategoriesPage = () => {
  const { categoryName } = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch primary category information
        const primaryResponse = await fetch(`${process.env.REACT_APP_API_URL}/maincategories/descriptions`);
        const primaryData = await primaryResponse.json();
        const primaryInfo = primaryData.find(category => category.pri_category === categoryName);
        setPrimaryCategory(primaryInfo);

        // Fetch all products
        const productsResponse = await fetch(`${process.env.REACT_APP_API_URL}/products`);
        const allProducts = await productsResponse.json();
        
        // Filter products based on the selected primary category
        const filteredProducts = allProducts.filter(product => product.pri_category === categoryName);

        const categoryMap = filteredProducts.reduce((acc, product) => {
          if (!acc[product.category]) {
            acc[product.category] = product.images;
          }
          return acc;
        }, {});

        const uniqueCategories = Object.keys(categoryMap).map(category => ({
          name: category,
          image: categoryMap[category]
        }));

        setCategories(uniqueCategories);
        setProducts(filteredProducts);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [categoryName]);

  const handleBack = () => {
    if (selectedProduct) {
      setSelectedProduct(null);
      window.scrollTo(0, 0);
    } else if (selectedCategory) {
      setSelectedCategory(null);
      window.scrollTo(0, 0);
    } else {
      navigate('/');
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    window.scrollTo(0, 0);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    window.scrollTo(0, 0);
  };

  const handleContact = (method) => {
    switch (method) {
      case 'email':
        window.location.href = 'mailto:sales@electriclink.co.ke';
        break;
      case 'whatsapp':
        window.open('https://wa.me/+254720133876', '_blank');
        break;
      case 'call':
        window.location.href = 'tel:+254722552969';
        break;
      default:
        alert(`Connecting via ${method}...`);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-red-500" size={48} />
      </div>
    );
  }

  if (selectedProduct) {
    return <Product product={selectedProduct} onBack={handleBack} />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
    <Header toggleCategoryMenu={() => {}} />
    <div className="container mx-auto px-4 py-8 mt-20">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-red-600 text-white p-4">
          <h1 className="text-3xl font-bold">
            {selectedCategory ? `${categoryName}: ${selectedCategory}` : categoryName}
          </h1>
        </div>

          <div className="p-4 sm:p-6">
            <button
              onClick={handleBack}
              className="mb-4 flex items-center text-black-600 hover:text-red-700"
            >
              <ChevronLeft className="h-5 w-5 mr-1" />
              {selectedCategory ? 'Back to Categories' : 'Back'}
            </button>

            {!selectedCategory && primaryCategory && (
              <div className="mb-6 flex flex-col sm:flex-row">
                <div className="sm:w-1/3 mb-4 sm:mb-0">
                  <img
                    src={primaryCategory.pri_image || "/api/placeholder/320/240"}
                    alt={primaryCategory.pri_category}
                    className="w-full h-auto object-contain rounded-lg"
                  />
                </div>
                <div className="sm:w-2/3 sm:pl-6 flex flex-col">
                  <div>
                    <h2 className="text-xl sm:text-2xl font-bold mb-2">{primaryCategory.pri_category}</h2>
                    <p className="text-base sm:text-lg mb-4">{primaryCategory.pri_description}</p>
                  </div>                  
                  <div className="flex flex-wrap justify-center gap-2 sm:gap-4 mt-4">
                    <button
                      onClick={() => handleContact('email')}
                      className="bg-red-500 text-white py-2 px-3 sm:px-4 rounded hover:bg-red-600 transition-colors flex items-center justify-center text-sm sm:text-base"
                    >
                      <Mail className="mr-1 sm:mr-2 h-4 w-4 sm:h-5 sm:w-5" />
                      Email Us
                    </button>
                    <button
                      onClick={() => handleContact('whatsapp')}
                      className="bg-green-500 text-white py-2 px-3 sm:px-4 rounded hover:bg-green-600 transition-colors flex items-center justify-center text-sm sm:text-base"
                    >
                      <MessageCircle className="mr-1 sm:mr-2 h-4 w-4 sm:h-5 sm:w-5" />
                      WhatsApp
                    </button>
                    <button
                      onClick={() => handleContact('call')}
                      className="bg-blue-500 text-white py-2 px-3 sm:px-4 rounded hover:bg-blue-600 transition-colors flex items-center justify-center text-sm sm:text-base"
                    >
                      <Phone className="mr-1 sm:mr-2 h-4 w-4 sm:h-5 sm:w-5" />
                      Call Us
                    </button>
                  </div>
                </div>
              </div>
            )}

            {!selectedCategory && (
              <div className="text-center mb-6 sm:mb-8">
                <p className="text-base sm:text-lg font-semibold mb-2">Scroll down to see {categoryName} categories</p>
                <ChevronDown className="animate-bounce mx-auto h-6 w-6 sm:h-8 sm:w-8 text-red-500" />
              </div>
            )}

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 sm:gap-6">
              {!selectedCategory ? (
                // Display categories
                categories.map((category, index) => (
                  <div
                    key={index}
                    className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col"
                  >
                    <div className="h-32 sm:h-48 relative overflow-hidden">
                      <img
                        src={category.image || "/api/placeholder/320/240"}
                        alt={category.name}
                        className="absolute w-full h-full object-contain"
                      />
                    </div>
                    <div className="p-2 sm:p-4 flex-grow flex flex-col justify-between">
                      <h3 className="text-sm sm:text-xl font-semibold mb-2 truncate">{category.name}</h3>
                      <button
                        onClick={() => handleCategoryClick(category.name)}
                        className="w-full bg-black text-white py-1 sm:py-2 px-2 sm:px-4 rounded hover:bg-gray-800 transition-colors flex items-center justify-center mt-2 text-xs sm:text-base"
                      >
                        View Products
                        <ChevronRight className="ml-1 sm:ml-2 h-3 w-3 sm:h-5 sm:w-5" />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                // Display products of the selected category
                products
                  .filter(product => product.category === selectedCategory)
                  .map((product) => (
                    <div
                      key={product.id}
                      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer flex flex-col"
                      onClick={() => handleProductClick(product)}
                    >
                      <div className="h-32 sm:h-48 relative overflow-hidden">
                        <img
                          src={product.images || "/api/placeholder/320/240"}
                          alt={product.name}
                          className="absolute w-full h-full object-contain"
                        />
                      </div>
                      <div className="p-2 sm:p-4 flex-grow">
                        <h3 className="text-sm sm:text-lg font-semibold truncate">{product.name}</h3>
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChildCategoriesPage;