import React, { useState } from 'react';
import { Facebook, Linkedin, Mail, Phone, Code, MapPin, MessageCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically handle the newsletter subscription
    console.log('Subscribing email:', email);
    // Reset the email input
    setEmail('');
  };

  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8 sm:text-sm">
          {/* Help Section */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Need Help?</h3>
            <ul className="space-y-2">
              <li className="flex items-center">
                <Phone className="h-5 w-5 mr-2" />
                <span>+254 722 552969</span>
              </li>
              <li className="flex items-center">
                <Mail className="h-5 w-5 mr-2" />
                <a href="mailto:sales@electriclink.co.ke" className="hover:text-white transition-colors">sales@electriclink.co.ke</a>
              </li>
              <li className="flex items-center">
                <MapPin className="h-5 w-5 mr-2" />
                <span>DSM Place Ground Floor Kijabe Street</span>
              </li>
              <li className="flex items-center">
                <a href="https://wa.me/+254720133876" className="flex items-center hover:text-white transition-colors">
                  <MessageCircle className="h-5 w-5 mr-2" />
                  <span>WhatsApp Us</span>
                </a>
              </li>
            </ul>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="hover:text-white transition-colors" onClick={() => window.scrollTo(0, 0)}>About Us</Link>
              </li>
              <li>
                <Link to="/careers" className="hover:text-white transition-colors" onClick={() => window.scrollTo(0, 0)}>Careers</Link>
              </li>
              <li>
                <Link to="/service-center" className="hover:text-white transition-colors" onClick={() => window.scrollTo(0, 0)}>Service Center</Link>
              </li>
            </ul>
          </div>

          {/* Policies */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Policies</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/cookies-policy" className="hover:text-white transition-colors">Cookies Policy</Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="hover:text-white transition-colors">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-conditions" className="hover:text-white transition-colors">Terms & Conditions</Link>
              </li>
            </ul>
          </div>

          {/* Social Media and Newsletter */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Join Us On</h3>
            <div className="flex space-x-4 mb-6">
              <a href="https://web.facebook.com/p/Electric-Link-PAN-Africa-LTD-100065437562467/?_rdc=1&_rdr" className="text-gray-400 hover:text-white transition-colors">
                <Facebook className="h-6 w-6" />
                <span className="sr-only">Facebook</span>
              </a>
              <a href="https://ke.linkedin.com/company/electric-link-pan-africa-ltd" className="text-gray-400 hover:text-white transition-colors">
                <Linkedin className="h-6 w-6" />
                <span className="sr-only">LinkedIn</span>
              </a>
            </div>

            {/* Newsletter Form */}
            <h3 className="text-white text-lg font-semibold mb-2">Subscribe to Our Newsletter</h3>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="px-3 py-2 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-700 flex justify-between items-center">
          <div className="w-24"></div> {/* Spacer */}
          <p className="text-sm text-center flex-grow">
            &copy; {new Date().getFullYear()} ALL RIGHTS RESERVED. ELECTRIC LINK PAN AFRICA LIMITED.
          </p>
          <div className="w-24 text-right">
            <a href="https://github.com/njourou" className="text-gray-400 hover:text-white transition-colors" target="_blank" rel="noopener noreferrer">
              <Code className="h-6 w-6 inline-block" />
              <span className="sr-only">Developer</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;