import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const sliderData = [
  {
    id: 1,
    image: '/assets/sliders/4.jpg',
    description: 'Welcome to Electric Link Pan Africa Limited. Our Company boasts a wide range and readily available electrical and mechanical products all under one roof, displayed in modern showrooms.'
  },
  {
    id: 2,
    image: '/assets/sliders/1.png',
    description: 'Fans from RR offer a wide range which is premium looking, high on performance and priced economically for various residential, commercial, institutional, and industrial purposes.'
  },
  {
    id: 3,
    image: '/assets/sliders/2.png',
    description: 'We bring to you an exceptional and extensive range of LED Lighting products to suit every requirement across Residential, Commercial, Institutional & Industrial sectors.'
  },
  {
    id: 4,
    image: '/assets/sliders/3.png',
    description: 'Introducing our range of MCB, RCCB, Isolator, MCB Changeover & Distribution Board for both residential and commercial use.'
  }
];

const ProductSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderData.length);
    }, 8000);

    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + sliderData.length) % sliderData.length);
  };

  return (
    <div className="relative w-full h-96 overflow-hidden rounded-lg">
      {sliderData.map((slide, index) => (
        <div
          key={slide.id}
          className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
            index === currentSlide ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {index === 0 ? (
            <div className="relative w-full h-full">
              <img
                src={slide.image}
                alt={`Slide ${slide.id}`}
                className="w-full h-full object-cover"
                style={{
                  animation: index === currentSlide ? 'zoom 15s ease-in-out infinite alternate' : 'none'
                }}
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <p className="text-white text-center text-xs sm:text-sm md:text-base lg:text-lg px-4 max-w-2xl">
                  {slide.description}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex h-full" style={{ backgroundColor: '#FF204E' }}>
              <div className="w-1/2 p-2 sm:p-4 flex flex-col justify-center text-white">
                <p className="text-xs sm:text-sm md:text-base lg:text-lg">{slide.description}</p>
              </div>
              <div className="w-1/2 flex items-center justify-center p-2 sm:p-4">
                <img
                  src={slide.image}
                  alt={`Slide ${slide.id}`}
                  className="max-h-full max-w-full object-contain"
                />
              </div>
            </div>
          )}
        </div>
      ))}
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-1 sm:left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-1 rounded-full z-10"
      >
        <ChevronLeft size={16} />
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-1 sm:right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-1 rounded-full z-10"
      >
        <ChevronRight size={16} />
      </button>
      <style jsx>{`
        @keyframes zoom {
          0% {
            transform: scale(1);
          }
          100% {
            transform: scale(1.1);
          }
        }
      `}</style>
    </div>
  );
};

export default ProductSlider;