import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { Phone, MapPin, Mail } from 'lucide-react';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#000000] to-[#001529] text-white p-8">
      <div className="container mx-auto">
        <h1 className="text-5xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-[#FF204E] to-[#FF5C79]">Contact Us</h1>
        
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          <div className="space-y-8">
            <div className="backdrop-blur-md bg-white bg-opacity-10 rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h2 className="text-2xl font-semibold mb-4 text-[#FF204E]">SHOWROOM</h2>
              <div className="space-y-3">
                <div className="flex items-center">
                  <Phone className="mr-3 text-[#FF204E]" />
                  <span>+254 722 552 969</span>
                </div>
                <div className="flex items-center">
                  <MapPin className="mr-3 text-[#FF204E]" />
                  <span>DSM Kijabe Street Nairobi</span>
                </div>
                <div className="flex items-center">
                  <Mail className="mr-3 text-[#FF204E]" />
                  <span>sales@electriclink.co.ke</span>
                </div>
              </div>
            </div>

            <div className="backdrop-blur-md bg-white bg-opacity-10 rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h2 className="text-2xl font-semibold mb-4 text-[#FF204E]">SERVICE CENTER</h2>
              <div className="space-y-3">
                <div className="flex items-center">
                  <Phone className="mr-3 text-[#FF204E]" />
                  <span>+254 720 133 691</span>
                </div>
                <div className="flex items-center">
                  <MapPin className="mr-3 text-[#FF204E]" />
                  <span>Behind DSM Kijabe Street Nairobi</span>
                </div>
                <div className="flex items-center">
                  <Mail className="mr-3 text-[#FF204E]" />
                  <span>sales@electriclink.co.ke</span>
                </div>
              </div>
            </div>
          </div>

          <div className="backdrop-blur-md bg-white bg-opacity-10 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7977.655689646692!2d36.81303999203492!3d-1.2767090242132564!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f172db4e42347%3A0x458d8779cdfb818b!2sElectric%20Link%20PAN%20Africa%20Limited!5e0!3m2!1sen!2sus!4v1727781578437!5m2!1sen!2sus"
              width="100%"
              height="100%"
              style={{ border: 0, minHeight: '400px' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          <div className="backdrop-blur-md bg-white bg-opacity-10 rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4 text-[#FF204E]">Contact Form</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                variant="outlined"
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{ style: { color: 'white' } }}
                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' } } }}
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                variant="outlined"
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{ style: { color: 'white' } }}
                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' } } }}
              />
              <TextField
                fullWidth
                label="Message"
                name="message"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                required
                variant="outlined"
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{ style: { color: 'white' } }}
                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' } } }}
              />
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: '#FF204E', color: 'white', padding: '10px 20px', fontSize: '1rem' }}
                className="hover:bg-[#FF5C79] transition-colors duration-300"
              >
                Send Message
              </Button>
            </form>
          </div>

          <div className="backdrop-blur-md bg-white bg-opacity-10 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
  <img 
    src="/assets/12.jpg" 
    alt="Classifieds"
    className="w-full h-100 object-cover" // Set a specific height
  />
</div>

        </div>
      </div>
    </div>
  );
};

export default ContactUs;