import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardContent, CardHeader, Typography } from '@mui/material'; // Replace with the actual library you are using
import { Zap } from 'lucide-react';
import Header from '../components/Header';  
import Footer from '../components/Footer';  
import PartnersSlider from '../components/PartnersSlider';

const ServiceCenter = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Helmet>
        <title>Our Service Center</title>
        <meta name="description" content="Learn about our service center and the range of products we support." />
      </Helmet>
      <Header />
      <main className="container mx-auto p-4 mt-[120px] md:mt-[100px]">
        <div className="container mx-auto p-4">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold mb-8 text-center text-[#7d221c]">Our Service Center</h1>
            
            <div className="grid gap-8 mb-8">
              <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
                <CardHeader className="bg-[#7d221c] text-white">
                  <Typography variant="h6" className="flex items-center">
                    <Zap className="mr-2" />
                    Electric Link Pan Africa Limited
                  </Typography>
                </CardHeader>
                <CardContent className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Our well-qualified service center team has over 40 years of experience in the maintenance and repair of a wide range of portable diesel and petrol generators, lawnmowers, welding machines, air compressors, high-pressure washers, woodworking machines, and inverters.
                  </p>
                  <p className="text-gray-700 leading-relaxed mt-2">
                    The service center stocks a wide range of genuine spare parts for the above machines. In addition to having modern testing and calibrating equipment, we also ensure all repairs are carried out to the full satisfaction of the client.
                  </p>
                </CardContent>
              </Card>
            </div>

            
          </div>
        </div>
        <PartnersSlider />
      </main>
      <Footer />
    </div>
  );
};

export default ServiceCenter;

