import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { Briefcase, Users, Star, ArrowRight } from 'lucide-react';
import Footer from '../components/Footer'; 

const CareersPage = () => {
  const jobOpenings = [
    { title: "Electrical Engineer", department: "Engineering" },
    { title: "Sales Representative", department: "Sales" },
    { title: "Customer Service Specialist", department: "Customer Support" },
    { title: "Inventory Manager", department: "Operations" },
  ];

  return (
<div className="min-h-screen bg-gray-100">
<main className="container mx-auto p-4 mt-[120px] md:mt-[100px]">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl sm:text-4xl font-bold mb-8 text-center text-[#7d221c]">Careers</h1>
          
          <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 mb-8">
            <CardHeader className="bg-[#7d221c] text-white">
              <h2 className="text-xl font-semibold flex items-center">
                <Briefcase className="mr-2" />
                Join Our Team
              </h2>
            </CardHeader>
            <CardContent className="mt-4">
              <p className="text-gray-700 leading-relaxed">
                At Electric Link Pan Africa Limited, we're always looking for talented individuals to join our team of 90+ professionals. We offer exciting opportunities in the electrical and mechanical products industry, with positions available in our Nairobi CBD locations.
              </p>
            </CardContent>
          </Card>

          

          <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 mb-12">
            <CardHeader className="bg-[#7d221c] text-white">
              <h2 className="text-xl font-semibold flex items-center">
                <Briefcase className="mr-2" />
                Current Openings
              </h2>
            </CardHeader>
            <CardContent className="mt-4">
              <ul className="space-y-4">
                {jobOpenings.map((job, index) => (
                  <li key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                    <div>
                      <h3 className="font-semibold text-lg">{job.title}</h3>
                      <p className="text-gray-600">{job.department}</p>
                    </div>
                    <ArrowRight className="text-[#7d221c]" />
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>

          <div className="text-center">
            <p className="text-xl font-semibold text-[#7d221c] mb-4">Ready to join our team?</p>
            <button className="bg-[#7d221c] text-white px-6 py-2 rounded-full hover:bg-[#9d423c] transition-colors duration-300">
              Apply Now
            </button>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default CareersPage;