import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react'; 
import MainPage from './components/MainPage';
import ChildCategoriesPage from './components/ChildCategoriesPage';
import ProductListPage from './components/ProductListPage';
import AboutUs from './Pages/AboutUs';
import Careers from './Pages/Careers'; 
import ServiceCenter from './Pages/ServiceCenter'; 
import ScrollToTop from './components/ScrollToTop'; 
import Header from './components/Header'; 
import ProductPage from './components/Product';
import ContactUs from './Pages/ContactUs';
import SearchResults from './components/SearchResults';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Header />
        <Analytics /> 
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/category/:categoryName" element={<ChildCategoriesPage />} />
          <Route path="/products/:categoryName" element={<ProductListPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contacts" element={<ContactUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/service-center" element={<ServiceCenter />} />
          <Route path="/product/:productId" element={<ProductPage />} />
        </Routes>

       
        <FloatingWhatsApp
          phoneNumber="+254720133876" 
          accountName="Electric Link Pan Africa Limited" 
          allowClickAway
          notification
          notificationDelay={60000} 
          notificationSound
          avatar="/logo-1.png"
          chatMessage="Hello, how can we help you today?"
          placeholder="Type a message..."
          statusMessage="Online"
          allowEsc
          allowResizing
          position="left"
          headerColor="#7d221c"
          headerBgColor="#fff"
          iconColor="#7d221c"
          iconBgColor="#fff"
          borderRadius="10px"

        />
      </div>
    </Router>
  );
}

export default App;
