import React from 'react';
import { Helmet } from 'react-helmet';
import { ArrowLeft, Loader, Phone, Mail, MessageSquare } from 'lucide-react';
import Header from './Header';
import Footer from './Footer';

const Product = ({ product, onBack }) => {
  if (!product) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  const cleanSmallDescription = product.small_description ? product.small_description.replace(/\\n/g, '').replace(/&nbsp;/g, '') : '';

  return (
    <div className="min-h-screen bg-gray-100">
      <Helmet>
        <title>{product.name} - {product.pri_category}</title>
        <meta name="description" content={cleanSmallDescription} />
        <meta property="og:title" content={product.name} />
        <meta property="og:description" content={cleanSmallDescription} />
        <meta property="og:image" content={product.images || "/api/placeholder/400/400"} />
      </Helmet>

      <Header toggleCategoryMenu={() => {}} />

      <div className="container mx-auto px-4 py-8 mt-20">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="bg-red-600 text-white p-4">
            <h1 className="text-2xl md:text-3xl font-bold">{product.name}</h1>
          </div>

          <div className="p-4 md:p-6">
            <button 
              onClick={onBack} 
              className="flex items-center text-gray-600 hover:text-gray-800 mb-4 md:mb-6"
            >
              <ArrowLeft size={20} className="mr-2" />
              <span className="text-sm md:text-base">Back to Products</span>
            </button>
            
            <div className="flex flex-col md:flex-row gap-6 md:gap-8">
              <div className="w-full md:w-1/2">
                <img 
                  src={product.images || "/api/placeholder/400/400"} 
                  alt={product.name} 
                  className="w-full h-[300px] md:h-[500px] object-contain bg-gray-100 rounded-lg" 
                />
              </div>
              <div className="w-full md:w-1/2">
                <div className="mb-4 md:mb-6 text-base md:text-lg prose">
                  <div dangerouslySetInnerHTML={{ __html: cleanSmallDescription }} />
                </div>
                
                <p className="mb-2 md:mb-4 text-base md:text-lg"><span className="font-semibold">Main Category:</span> {product.pri_category}</p>
                <p className="mb-4 md:mb-6 text-base md:text-lg"><span className="font-semibold">Category:</span> {product.category}</p>

                <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
                  <a 
                    href="tel:+254722552969" 
                    className="bg-[#FF204E] text-white px-4 py-3 rounded-md font-bold text-base md:text-lg flex items-center justify-center hover:bg-[#cc1a3f] transition-colors"
                  >
                    <Phone className="mr-2" size={20} />
                    Call Us
                  </a>

                  <a 
                    href="mailto:sales@electriclink.co.ke" 
                    className="bg-[#A0153E] text-white px-4 py-3 rounded-md font-bold text-base md:text-lg flex items-center justify-center hover:bg-[#801236] transition-colors"
                  >
                    <Mail className="mr-2" size={20} />
                    Email Us
                  </a>

                  <a 
                    href="https://wa.me/+254720133876" 
                    className="bg-[#25D366] text-white px-4 py-3 rounded-md font-bold text-base md:text-lg flex items-center justify-center hover:bg-[#20b957] transition-colors"
                  >
                    <MessageSquare className="mr-2" size={20} />
                    WhatsApp
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>      
      </div>
      <Footer />
    </div>
  );
};

export default Product;