import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShoppingBag, Truck, HelpCircle, Gift, SquarePower, Lightbulb, ChevronRight, Fan, CirclePower, PlugZap, ShowerHead, Cable, Loader, Menu, Search } from 'lucide-react';
import ProductSlider from './ProductSlider';
import CategorySlider from './CategorySlider';
import Footer from './Footer';
import PartnersSlider from './PartnersSlider';

const MainPage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [flashSales, setFlashSales] = useState([]);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [productsResponse, categoriesResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/products`),
          fetch(`${process.env.REACT_APP_API_URL}/maincategories`)
        ]);
        
        if (!productsResponse.ok || !categoriesResponse.ok) {
          throw new Error(`HTTP error!`);
        }

        const productsData = await productsResponse.json();
        const categoriesData = await categoriesResponse.json();

        setProducts(productsData);
        setCategories(categoriesData);
        updateFlashSales(productsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      updateFlashSales(products);
    }, 3600000);

    return () => clearInterval(interval);
  }, []);

  const updateFlashSales = (allProducts) => {
    const shuffled = allProducts.sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, 10).map(product => ({
      ...product,
      discountPercentage: Math.floor(Math.random() * 2) + 4,
      image: `/api/placeholder/${Math.floor(Math.random() * 300) + 100}/${Math.floor(Math.random() * 300) + 100}`
    }));
    setFlashSales(selected);
  };

  const handleMainCategoryClick = (category) => {
    navigate(`/category/${encodeURIComponent(category)}`);
    setIsCategoryOpen(false);
  };

  const handleProductClick = (product) => {
    console.log('Product clicked:', product);
    // Navigate to product detail page
    navigate(`/product/${product.id}`);
  };

  const toggleCategoryMenu = () => {
    setIsCategoryOpen(!isCategoryOpen);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;
    navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
  };

  const LoadingSpinner = () => (
    <div className="flex justify-center items-center h-full">
      <Loader className="animate-spin text-red-500" size={48} />
    </div>
  );

  const getCategoryIcon = (category) => {
    const icons = {
      electronics: <Lightbulb size={16} />,
      jewelery: <Gift size={16} />,
      "RR LIGHTING": <Lightbulb size={16} />,
      "RR FANS": <Fan size={16} />,
      "RR SWITCH GEAR": <SquarePower size={16} />,
      "RR SWITCHES": <CirclePower size={16} />,
      "MICROLITE": <PlugZap size={16} />,
      "SHOWERS": <ShowerHead size={16} />,
      "MACHINERY": <Cable size={16} />,
      "RR Electrical Accessories": <PlugZap size={16} />,
    }
    return icons[category] || <ShoppingBag size={16} />
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Mobile Category Menu Toggle Button */}
      <button 
        className="fixed top-4 left-4 z-50 md:hidden bg-white p-2 rounded-full shadow-md"
        onClick={toggleCategoryMenu}
      >
        <Menu size={24} />
        <span className="sr-only">Toggle Categories</span>
      </button>

      {/* Search Bar */}
      <div className="fixed top-0 left-0 right-0 z-40 bg-white shadow-md">
        <div className="container mx-auto px-4 py-2">
          <form onSubmit={handleSearch} className="flex">
            <input
              type="search"
              placeholder="Search products, brands and categories"
              className="w-full rounded-l-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-[#d01f16] focus:border-transparent"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
            <button 
              type="submit"
              className="bg-[#d01f16] text-white px-4 py-2 rounded-r-md hover:bg-[#b01a12] transition-colors duration-200"
            >
              <Search className="h-5 w-5" />
              <span className="sr-only">Search</span>
            </button>
          </form>
        </div>
      </div>

      {/* Mobile Category Menu */}
      {isCategoryOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="bg-white w-64 h-full overflow-y-auto">
            <div className="p-4">
              <button onClick={toggleCategoryMenu} className="mb-4">
                <ChevronRight className="h-6 w-6" />
                <span className="sr-only">Close Categories</span>
              </button>
              <h2 className="mb-4 text-lg font-bold">Categories</h2>
              <nav className="flex flex-col space-y-2">
                {categories.map((category, index) => (
                  <button
                    key={index}
                    className="flex items-center rounded-lg px-3 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                    onClick={() => handleMainCategoryClick(category)}
                  >
                    <span className="mr-3">{getCategoryIcon(category)}</span>
                    <span className="font-bold">{category}</span>
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      )}
 
      {/* Main Content */}
      <main className="flex-grow pt-32">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row gap-4">
            {/* Categories - Hidden on mobile, visible on desktop */}
            <aside className="hidden md:block w-1/6 bg-white p-4 rounded-md shadow-md mb-4 md:mb-0 md:h-[400px] overflow-y-auto">
              <h2 className="font-bold mb-4 text-sm">Categories</h2>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <ul className="text-xs">
                  {categories.map((category, index) => (
                    <li 
                      key={index} 
                      className="py-1 hover:bg-gray-100 cursor-pointer flex items-center"
                      onClick={() => handleMainCategoryClick(category)}
                    >
                      <span className="mr-2">{getCategoryIcon(category)}</span>
                      {category}
                    </li>
                  ))}
                </ul>
              )}
            </aside>

            {/* Main banner area */}
            <div className="w-full md:w-5/6">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div>
                  <div className="flex flex-col md:flex-row gap-4">
                    {/* Product Slider */}
                    <div className="md:w-3/4">
                      <ProductSlider products={products.slice(0, 5)} />
                    </div>
                    {/* Help Center and Easy Return tiles */}
                    <div className="w-full md:w-1/4 flex flex-row md:flex-col gap-4">
                      <div className="flex-1 bg-gradient-to-r from-[#FF204E] to-[#FF204E] p-4 rounded-md shadow-md flex items-center text-white">
                        <HelpCircle className="mr-2 flex-shrink-0" size={24} />
                        <div className="min-w-0">
                          <h3 className="font-bold text-sm md:text-base truncate">Help Center</h3>
                          <p className="text-xs md:text-sm truncate">Our agents are always<br /> ready to help</p>
                        </div>
                      </div>
                      <div className="flex-1 bg-gradient-to-r from-[#00224D] to-[#00224D] p-4 rounded-md shadow-md flex items-center text-white">
                        <Truck className="mr-2 flex-shrink-0" size={24} />
                        <div className="min-w-0">
                          <h3 className="font-bold text-sm md:text-base truncate">After Sales <br/>Services</h3>
                          <p className="text-xs md:text-sm truncate">
                            Support beyond <br />your purchase
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Full-width Item categories */}
        <CategorySlider onCategoryClick={handleMainCategoryClick} />

        {/* Flash Sales */}
        {!isLoading && (
  <div className="container mx-auto px-4 py-8">
    <h2 className="text-2xl font-bold mb-4">Flash Sales</h2>
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
      {flashSales.map(product => (
        <div 
          key={product.id} 
          className="bg-white p-4 rounded-md shadow-md transition-transform hover:scale-105 relative"
        >
          <div className="w-full h-48 relative">
            <img
              src={product.images}
              alt={product.name}
              className="w-full h-full object-contain"
            />
            <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 text-xs font-bold rounded-bl">
              IN STOCK NOW
            </div>
          </div>
          <h3 className="font-bold mb-2 truncate text-sm">{product.name}</h3>
        
        </div>
      ))}
    </div>
  </div>
)}

        <div className="container mx-auto px-4 py-8">
          <PartnersSlider />
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default MainPage;