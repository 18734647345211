import React, { useRef, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const CategorySlider = ({ onCategoryClick }) => {
  const sliderRef = useRef(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/maincategories/descriptions`);
        const data = await response.json();

        const uniqueCategories = new Map();
        data.forEach(item => {
          if (
            item.pri_category && 
            !uniqueCategories.has(item.pri_category) && 
            item.pri_category !== "RR ELECTRICAL ACCESSORIES" // Exclude this category
          ) {
            uniqueCategories.set(item.pri_category, {
              name: item.pri_category,
              imageUrl: item.pri_image || "/placeholder.svg?height=200&width=200"
            });
          }
        });

        const categoriesArray = Array.from(uniqueCategories.values());
        setCategories(categoriesArray);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const scroll = (direction) => {
    if (sliderRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      sliderRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-white py-8 mt-8">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-bold mb-4 text-center">Diverse range of product categories</h2>
        <div className="relative">
          <div className="overflow-x-auto scrollbar-hide" ref={sliderRef}>
            <div className="flex gap-4 md:gap-6" style={{ width: 'max-content' }}>
              {categories.map((category, index) => (
                <div
                  key={index}
                  className="w-[150px] md:w-[200px] flex-shrink-0 text-center cursor-pointer hover:shadow-lg transition-shadow rounded-lg overflow-hidden bg-gray-50"
                  onClick={() => onCategoryClick(category.name)}
                >
                  <div className="h-32 md:h-40 w-full mb-2 overflow-hidden">
                    <img src={category.imageUrl} alt={category.name} className="w-full h-full object-cover transition-transform duration-300 hover:scale-110" />
                  </div>
                  <p className="text-sm md:text-base font-medium py-2 px-2 truncate">{category.name}</p>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
            aria-label="Scroll left"
          >
            <ChevronLeft className="h-6 w-6" />
          </button>
          <button
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
            aria-label="Scroll right"
          >
            <ChevronRight className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategorySlider;