import React from 'react';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { Zap, Target, Eye } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet'; // Import Helmet

const AboutUs = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100"> {/* Added gray background here */}
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="Learn about Electric Link Pan Africa Limited, our mission, vision, and commitment to providing high-quality electrical and mechanical products." />
      </Helmet>
      <Header />
      <main className="container mx-auto p-4 mt-[120px] md:mt-[100px]">
        <div className="container mx-auto p-4">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold mb-8 text-center text-[#7d221c]">About Us</h1>
            
            <div className="grid gap-8 mb-8">
              <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
                <CardHeader className="bg-[#7d221c] text-white">
                  <Typography variant="h6" className="flex items-center">
                    <Zap className="mr-2" />
                    Electric Link Pan Africa Limited
                  </Typography>
                </CardHeader>
                <CardContent className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Electric Link Pan Africa Limited is a renowned firm managed by experienced professionals overseeing a team of 90 employees. The Company boasts a wide range and readily available electrical and mechanical products all under one roof, displayed in modern showrooms, strategically located in Nairobi CBD and Industrial Area, supported by a well-equipped in-house service center with trained technicians providing timely support to clients.
                  </p>
                </CardContent>
              </Card>
            </div>

            <div className="grid md:grid-cols-2 gap-8">
              <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
                <CardHeader className="bg-[#7d221c] text-white">
                  <Typography variant="h6" className="flex items-center">
                    <Target className="mr-2" />
                    Our Mission
                  </Typography>
                </CardHeader>
                <CardContent className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Electric Link Pan Africa Limited strives to meet all clients' electrical and mechanical needs and expectations by ensuring consistency in quality, availability, competitive pricing, exemplary service, integrity, and continuous development of new and innovative products.
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
                <CardHeader className="bg-[#7d221c] text-white">
                  <Typography variant="h6" className="flex items-center">
                    <Eye className="mr-2" />
                    Our Vision
                  </Typography>
                </CardHeader>
                <CardContent className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Electric Link Pan Africa Limited will endeavor to focus on exceeding clients' expectations by continuously providing the critical LINK between reputable manufacturers and the ever-demanding regional marketplace.
                  </p>
                </CardContent>
              </Card>
            </div>

            <div className="mt-12 text-center">
              <svg className="w-24 h-24 mx-auto text-[#7d221c]" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="45" fill="none" stroke="currentColor" strokeWidth="8" />
                <path d="M30 50 L45 65 L70 35" fill="none" stroke="currentColor" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p className="mt-4 text-xl font-semibold text-[#7d221c]">Your Trusted Electrical and Mechanical Partner</p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AboutUs;
