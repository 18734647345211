import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronLeft, Loader } from 'lucide-react';

const ProductListPage = () => {
  const { categoryName } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products`);
        const allProducts = await response.json();
        const filteredProducts = allProducts.filter(product => product.category === categoryName);
        setProducts(filteredProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [categoryName]);

  const handleBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <button
        onClick={handleBack}
        className="mb-4 flex items-center text-blue-500 hover:text-blue-700"
      >
        <ChevronLeft className="h-5 w-5 mr-1" />
        Back
      </button>
      <h1 className="text-3xl font-bold mb-6">{categoryName}</h1>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {products.map((product) => (
          <div
            key={product.id}
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer"
          >
            <div className="h-40 mb-4">
              <img
                src={product.images || "/api/placeholder/160/160"}
                alt={product.name}
                className="w-full h-full object-cover rounded-md"
              />
            </div>
            <h2 className="text-lg font-semibold text-center truncate">{product.name}</h2>
         
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductListPage;